import { useState } from "react"
import { intervalToDuration } from 'date-fns'

type VideoInfo = {
    id: string,
    duration: number,
    title: string
}

type PodCont = {
    title: string,
    parts: Array<string>
}

const zeroPad = (num:any) => String(num).padStart(2, '0')

const videoInfoParts = (item: VideoInfo) => {
    const seconds = item.duration
    const title = item.title

    const fiveMin = 60 * 5
    const fullBlocks = Math.floor(seconds / fiveMin);
    const partBlock = (seconds % fiveMin) > 0 ? 1 : 0
    const blocks = fullBlocks + partBlock

    const outputList = []

    let x=0

    for(x=0;x<blocks;x++) {
        const second = x * fiveMin
        const percent = Math.floor((x * 100) / blocks)
        const minuteFrom = intervalToDuration({ start: 0, end: (x * fiveMin) * 1000 })
        let minuteTo = intervalToDuration({ start: 0, end:  ((x+1) * fiveMin) * 1000 })

        if(x === (blocks-1)) {
            let duration = item.duration;
            if(duration % 60 !== 0) {
                duration += 60
            }
            minuteTo = intervalToDuration({start: 0, end: (duration * 1000)})

        }

        const from = `${zeroPad(minuteFrom.hours || 0)}:${zeroPad(minuteFrom.minutes || 0)}`
        const to = `${zeroPad(minuteTo.hours || 0 )}:${zeroPad(minuteTo.minutes || 0)}`
        
        const output=`- [ ] [${title} ${x+1}/${blocks} (${from}-${to}) (${percent}%)](https://www.youtube.com/watch?v=${item.id}&t=${second}s)\n`
        outputList.push(output)
    }

    return outputList
}

const getStartVideo = (item: VideoInfo) => {
    return videoInfoParts(item)[0]
}

const getFinishVideo = (item: VideoInfo) => {
    const parts = videoInfoParts(item)

    if(parts.length <=1) return null

    return parts[parts.length-1]
}

const pad = (value:string) => {
    if(value.length<2) return '0' + value
    return value
}

const parseDuration = (value:string) => {
    let array=value.replace("PT","").replace("H", "H:").replace("M", "M:").split(":")

    let hours="0"
    let minutes="0"
    let seconds="0"

    array.forEach(item => {
        if(item.endsWith("H")) {
            hours=item.replace("H", "")
        } else if(item.endsWith("M")) {
            minutes=item.replace("M", "")
        } else if(item.endsWith("S")) {
            seconds=item.replace("S", "")
        }
    })

    return pad(hours) + ":" + pad(minutes) + ":" + pad(seconds)
}

const YtbPodcastForm = () => {
    const [url, setUrl] = useState<string>('')
    const [urls, setUrls] = useState<Array<string>>([])
    const [fetching, setFetching] = useState<boolean>(false)
    const [start, setStart] = useState<Array<string>>([])
    const [podCont, setPodCont] = useState<Array<PodCont>>([])
    const [finish, setFinish] = useState<Array<string>>([])
    const [rendered, setRendered] = useState<Array<string>>([])
    const [active, setActive] = useState<string>('')
    const [error, setError] = useState<string | undefined>(undefined)

    const requestInfo = async () => {
        try {
            setFetching(true)
            const validUrls = urls
            .filter(url => url !== "")

            const apiKey=process.env.REACT_APP_YTB_API_KEY

            let ids=validUrls.map(url => {
                let id = ''
                if(url.includes('youtu.be')) {
                    // https://youtu.be/8jcUIx7IfsQ?si=XlbNB_z8UGpjQ6J0
                    id = url.split("/").slice(-1)[0].split("?")[0]
                } else if(url.includes("youtube.com/watch")) {
                    // https://www.youtube.com/watch?v=RlM9AfWf1WU&whatever=something
                    id = url.split("/").slice(-1)[0].replace("watch?", "").replace("?", "&").split("&").filter(it => it.startsWith("v="))[0].split("=")[1]
                } else if(url.includes("youtube.com/live/")) {
                    // https://www.youtube.com/live/KqLtW8d8PXY?si=K5DLydwQikjx8gcW
                    id = url.split("/").slice(-1)[0].replace("?", "&").split("&")[0]
                }
                
                return id
            })

            const videoIds = ids.join(',')

            let url = `https://www.googleapis.com/youtube/v3/videos?id=${videoIds}&part=snippet,contentDetails&key=${apiKey}`

            let resp = await (await fetch(url)).json()

            let data = resp.items.map((item:any) => {
                let id = item.id
                let durationStr = parseDuration(item.contentDetails.duration)
                let title = item.snippet.title

                let durationReversed = durationStr.split(":").reverse()

                let secondsInPart=1
                let duration=0

                durationReversed.forEach((part: any) => {
                    duration += parseInt(part) * secondsInPart
                    secondsInPart *= 60
                })

                return {
                    id: id,
                    duration: duration,
                    title: title
                }
            })

            console.log(data)

            /*
            let resp = await fetch("https://api-ytb.johnsona.net/info", 
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        urls: validUrls
                    })
                })

            let data = await resp.json()
            */

            const starts = data.map((item: VideoInfo) => getStartVideo(item))
            const podCont = data.map((item: VideoInfo) => {
                const parts = videoInfoParts(item)
                delete parts[0]
                delete parts[parts.length-1]

                return {
                    title: `${item.title} (${parts.length})`,
                    parts: parts
                }
            })

            
            const finishes = data.map((item: VideoInfo) => getFinishVideo(item)).filter((item:string) => item != null)
            setStart(starts)
            setPodCont(podCont)
            setFinish(finishes)
            setRendered(starts)
            setActive('start')

        } catch(err) {
            console.error(err)
            setError(`${err}`)
        }

        setFetching(false)
        
    }

    const addUrl = () => {
        const newUrls = url.split("\n")
        const allUrls = [...urls, ...newUrls]
        setUrls(
            allUrls
        )

        setUrl('')
    }

    const pasteUrl = async () => {
        try {
            const copiedUrl = await navigator.clipboard.readText()

            setUrls([
                ...urls,
                copiedUrl
            ])
        } catch(err) {
            console.error(err)
        }
    }

    const copyToClipboard = (items: Array<string>) => {
        try {
            let text = ""

            items.forEach(item => {
                text += item + "\n"
            })


            navigator.clipboard.writeText(text)
        } catch(err){
            console.error(err)
        }
    }

    const copyAndRender = (items: Array<string>, activate: string) => {
        copyToClipboard(items)
        setRendered(items)
        setActive(activate)
    }

    const copyAndRenderCont = (items: Array<PodCont>, activate: string) => {
        const itemsToClipboard = items.map((item: PodCont) => {
            let text = `- ${item.title}\n`

            item.parts.forEach((part: string) => { 
                text += "    " + part 
            })

            return text
        })

        const itemsToRender = items.map((item: PodCont) => { return `> ${item.title}` })


        console.log(itemsToClipboard)

        copyToClipboard(itemsToClipboard)
        setRendered(itemsToRender)
        setActive(activate)
    }

    const removeItem = (idx: number) => {
        const items = urls.filter((item, itemIdx) => itemIdx !== idx)
        
        setUrls(items)
    }
    
    return (
        <div className="container">
            <div className="row mb-2">
                <ol>
                {
                    urls.length > 0 && urls.map((item, idx) =>
                    <li key={idx}>
                        <span>{item}</span> &nbsp;&nbsp;&nbsp;&nbsp;
                        <a className="btn btn-primary" target="_blank" rel="noreferrer" href={`https://tmp-ytd.johnsona.net/mp3?url=${item}`}>Mp3</a> &nbsp;&nbsp;&nbsp;&nbsp;
                        <a className="btn btn-primary" target="_blank" rel="noreferrer" href={`https://tmp-ytd.johnsona.net/mp4?url=${item}`}>Mp4</a> &nbsp;&nbsp;&nbsp;&nbsp;                        <button className="btn btn-danger" onClick={ (e) => removeItem(idx)}>X</button>
                    </li>
                    )
                }
                </ol>
            </div>

            <div>
                <textarea
                    rows={10} cols={80}
                    className="col-12"
                    value={url} 
                    onChange={e => setUrl(e.target.value)} />
            </div>
            <div className="row mb-2">
                <button
                    className="btn btn-primary col-1 ml-4"
                    onClick={() => addUrl()}
                    >+</button>
                <button
                    className="btn btn-primary col-1"
                    onClick={() => pasteUrl()}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clipboard" viewBox="0 0 16 16">
                            <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"/>
                            <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"/>
                        </svg>
                    </button>
            </div>
            <div className="row mb-2">
                <button className="btn btn-primary"
                    onClick={() => requestInfo()}>
                    {fetching && (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    )}
                    Submit
                </button>
            </div>
            {start.length > 0 && (
                <div className="row">
                <ul className="nav nav-pills nav-fill">
                    <li className="nav-item">
                        <button className={"nav-link " + (active === 'start' ? 'active': '')} 
                            aria-current="page" 
                            onClick={()=> copyAndRender(start, 'start')}    
                        >Start ({start.length})</button>
                    </li>
                    <li className="nav-item">
                        <button className={"nav-link " + (active === 'cont' ? 'active': '')} 
                        onClick={()=> copyAndRenderCont(podCont, 'cont')}    
                        >Continue ({podCont.length})</button>
                    </li>
                    <li className="nav-item">
                        <button className={"nav-link " + (active === 'finish' ? 'active': '')} 
                        onClick={()=> copyAndRender(finish, 'finish')}    
                        >Finish ({finish.length})</button>
                    </li>
                </ul>
                <div>
                    {rendered.length > 0 && rendered.map(item => (
                        <p key={item}>{item}</p>
                    ))}
                </div>
            </div>
            )}
            {error && (
                <div className="row">
                    {error}
                </div>
            )}
        </div>
    )
}

export default YtbPodcastForm