import { useState } from 'react';
import './App.css';
import YtbForm from './YtbForm';
import ProtocoloForm from './ProtocoloForm';
import YtbPodcastForm from './YtbPodcastForm';

enum  SelectedItem {
  Ytb,
  Protocolo,
  YtbPodcast
}

function App() {
  const [selectedItem, setSelectedItem] = useState<SelectedItem>(SelectedItem.Ytb);

  return (
    <>
      <div>
        <ul className="nav nav-tabs">
          <li className="nav-item"><button className={`nav-link ${selectedItem === SelectedItem.Ytb && 'active'}`} onClick={() => setSelectedItem(SelectedItem.Ytb)}>Ytb</button></li>
          <li className="nav-item"><button className={`nav-link ${selectedItem === SelectedItem.Protocolo && 'active'}`} onClick={() => setSelectedItem(SelectedItem.Protocolo)}>Protocolo</button></li>
          <li className="nav-item"><button className={`nav-link ${selectedItem === SelectedItem.YtbPodcast && 'active'}`} onClick={() => setSelectedItem(SelectedItem.YtbPodcast)}>Ytb Podcast</button></li>
        </ul>
      </div>
      {selectedItem === SelectedItem.Ytb && (
        <YtbForm></YtbForm>
      )}
      {selectedItem === SelectedItem.Protocolo && (
        <ProtocoloForm></ProtocoloForm>
      )}
      {selectedItem === SelectedItem.YtbPodcast && (
        <YtbPodcastForm></YtbPodcastForm>
      )}
    </>
  );
}

export default App;
